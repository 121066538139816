/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// import React from "react";

// import "firebase/auth"
// import "firebase/storage"
// import "firebase/firestore"
// import "firebase/functions"

// import 'bootstrap/dist/css/bootstrap.min.css')
// import "prismjs/themes/prism-okaidia.css")
// import './src/assets/css/index.less')

// No idea why this is necessary
import "./src/styles/global.scss"
import "./src/components/NavbarStyles.scss"
import "./src/components/LogoTypeStyles.scss"
import "./src/components/ContentHomeStyles.scss"
import "./src/components/ContentAboutStyles.scss"
import "./src/components/ContentDevelopmentStyles.scss"
import "./src/components/ContentBlogStyles.scss"
import "./src/components/ContentContactStyles.scss"
import "./src/styles/BlogArticleTemplateStyles.scss"
import "./src/styles/WorkProjectTemplateStyles.scss"
import "./src/components/GalleryImageStyles.scss"
import "./src/components/LogoTypeStyles.scss"
import "./src/components/WorkUnitFeaturedStyles.scss"
import "./src/components/WorkUnitStyles.scss"


import wrapWithProvider from "./wrap-with-provider"
export const wrapRootElement = wrapWithProvider
