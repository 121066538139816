import { ANIM_PLAYED, SHOW_NAV } from '../actions/animationActions'

const animationInitialState = {
    played: false,
    show: false
}

const animationReducer = (state = animationInitialState, action) => {
   
   switch(action.type) {
    case ANIM_PLAYED:
        return {
            ...state,
            played: action.played // or true
        }
    case SHOW_NAV:
        return {
            ...state,
            show: action.show // or true
        }
    default:
        return state
   }

};

export default  animationReducer;

// export default function counter(state = 0, action) {
//     switch (action.type) {
//       case 'INCREMENT':
//         return state + 1
//       case 'DECREMENT':
//         return state - 1
//       default:
//         return state
//     }
//   }