import { combineReducers } from 'redux'
import animationReducer from './animationReducer'
//import myNewReducer from './myNewReducer'


export default combineReducers({
    anim: animationReducer
    // myNewReducer : myNewReducer // shortcut is list only once as above and below
})


// original example, multiple reducers
// import { combineReducers } from 'redux'
// import todos from './todos'
// import counter from './counter'

// export default combineReducers({
//   todos,
//   counter
// })