export const ANIM_PLAYED = 'ANIM_PLAYED';
export const SHOW_NAV = 'SHOW_NAV';

export const animState = (arg) => {
    return {
        type: ANIM_PLAYED,
        played: arg
    }
}

export const showNav = (arg) => {
    return {
        type: SHOW_NAV,
        show: arg
    }
}

