module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#E28800","showSpinner":true,"parent":"#gatsby-focus-wrapper"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mailgo/gatsby-browser.js'),
      options: {"plugins":[],"mailgoConfig":{"dark":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Archebyte","short_name":"Ab","start_url":"/","background_color":"#2C3D44","theme_color":"#86B900","display":"standalone","icon":"src/images/favicon-32x32.png","orientation":"portrait","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"54bbbb5742a2a3ec2c3ca081bab55b5a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Lato:100,300,400,700"],"urls":["/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
