// TODO: Need to genericize for any type of state storage
const ARCHEBYTE_STORE = "ARCHEBYTE_STORE";
// Load Animation State
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(ARCHEBYTE_STORE)
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch(err) {
    return undefined
  }
}

// Save Animation State
export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(ARCHEBYTE_STORE, serializedState)
  } catch(err) {
    console.log("Error saving data:"+err)
  }
}
