import React from "react"
import { loadState, saveState } from './src/state/actions/localStorage'
import { createStore as globalStore } from "redux"
import combineReducers from "./src/state/reducers/index"
import { Provider } from "react-redux"


// eslint-disable-next-line react/display-name,react/prop-types
const AllState = ({ element }) => { 
  const persistedState = loadState()

  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts  

  if (typeof window !== "undefined") {
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  } 



  const store = globalStore(
    combineReducers, 
    persistedState
    )
  store.subscribe(() => {
    // Determine here what items to save. 
    // Going to need to re-assemble store object on persistant retrieval. 

    saveState(store.getState()) // getState is a store method (not loadState (from localStorage))
    
    
    
    // saveState is localStorage (there is no save or set method on store)
    // console.log("STORE: ", store.getState());
  })
  
  return <Provider store={store}><div className="app">{element}</div></Provider>;
}

export default AllState;
